const mapEachCons = (array, num, cb) => {
  return Array.from(
    { length: array.length - num + 1 },
    (_, i) => array.slice(i, i + num)
  ).map(cb)
}

export const calcSmoothedOD = data =>
  mapEachCons(data.filter(el => el.optical_density != null), 5, group => {
    const [prev2, prev1, curr, next1, next2] = group

    const smoothedOD =
      (
        prev2.optical_density * Math.exp(-2) +
        prev1.optical_density * Math.exp(-1) +
        curr.optical_density * 0.5 +
        next1.optical_density * Math.exp(-1) +
        next2.optical_density * Math.exp(-2)
      ) / (0.5 + Math.exp(-1) * 2 + Math.exp(-2) * 2)

    return {
      ...curr,
      smoothedOD: smoothedOD
    }
  })

export const calcGrowSpeed = data => {
  const smoothedOD = calcSmoothedOD(data)

  return smoothedOD
    .map((el, idx, arr) => {
      const next = arr[idx + 1]

      if (!next) return undefined

      const hoursDiff =
        (Date.parse(next.recorded_at) - Date.parse(el.recorded_at)) / 1000 / 60 / 60

      return {
        ...el,
        smoothedGrowSpeed: (next.smoothedOD - el.smoothedOD) / hoursDiff
      }
    })
    .filter(el => el)
}

export const calcRelativeGrowSpeed = data => {
  const smoothedOD = calcSmoothedOD(data)

  const dODS = smoothedOD
    .map((el, idx, arr) => {
      const next = arr[idx + 1]

      if (!next) return undefined

      return {
        ...el,
        dODS: (next.smoothedOD - el.smoothedOD) / (next.smoothedOD + el.smoothedOD) * 2
      }
    })
    .filter(el => el)

  return mapEachCons(dODS, 5, group => {
    const [prev2, prev1, curr, next1, next2] = group

    const relativeGrowSpeed =
      (
        prev2.dODS * Math.exp(-2) +
        prev1.dODS * Math.exp(-1) +
        curr.dODS * 0.5 +
        next1.dODS * Math.exp(-1) +
        next2.dODS * Math.exp(-2)
      ) / (0.5 + Math.exp(-1) * 2 + Math.exp(-2) * 2)

    return {
      ...curr,
      relativeGrowSpeed: relativeGrowSpeed
    }
  })
}
