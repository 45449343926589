import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (this.initialized) return

    this.initialized = true

    this.setScrollPosition()
  }

  setScrollPosition() {
    const activePill = this.element.querySelector('.sowing-pill.active')
    if (!activePill) return

    const position = activePill.getBoundingClientRect().left
    this.element.scrollLeft = position
  }
}
