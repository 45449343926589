import { Controller } from "stimulus"
import { calcSmoothedOD, calcGrowSpeed, calcRelativeGrowSpeed } from 'utils/growSpeed'

const Highcharts = require('highcharts')
require('highcharts/highcharts-more')(Highcharts)

export default class extends Controller {
  static targets = [
    'chart',
    'acidityLast',
    'acidityMin',
    'acidityMax',
    'temperatureLast',
    'temperatureMin',
    'temperatureMax',
    'opticalDensityLast',
    'opticalDensityMin',
    'opticalDensityMax',
    'concentrationLast',
    'concentrationMin',
    'concentrationMax'
  ]

  connect() {
    if (this.initialized) return

    this.initialized = true

    setTimeout(() => {
      this.showComments = true
      this.initChart()
      this.fetchRecords()
    }, 100)
  }

  initChart() {
    this.chartTarget.highchart = new Highcharts.chart(this.chartTarget, this.chartOptions)
    this.highchart.showLoading()
  }

  fetchRecords() {
    fetch(this.element.getAttribute('data-records-url'), {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      }
    }).then(response => response.json())
      .then(data => this.setData(data))
  }

  setData(data) {
    this.setChartData(data)
    this.setIndicators()
  }

  setIndicators(min, max) {
    // console.log("setIndicators", min, max)
    // console.log(this.highchart.series[0])
    // return
    this.highchart.series.forEach(s => {
      if (["pH", "Temperature", "Optical density"].indexOf(s.name) == -1) {
        // console.log(s.name, "No")
        return
      }

      let points = s.data
      // console.log(s.name, "points length before period filter", points.length)
      if (min != undefined && max != undefined) {
        points = points.filter(p => {
          if (p.x < min) {
            return false
          }
          if (p.x > max) {
            return false
          }
          return true
        })
      }

      const values = points.map(p => p.y)
      const average = this.average(values)

      switch (s.name) {
      case "pH":
        this.acidityLast = average
        this.acidityMin = values.length > 0 ? Math.min(...values) : null
        this.acidityMax = values.length > 0 ? Math.max(...values) : null
        break
      case "Temperature":
        this.temperatureLast = average
        this.temperatureMin = values.length > 0 ? Math.min(...values) : null
        this.temperatureMax = values.length > 0 ? Math.max(...values) : null
        break
      case "Optical density":
        this.opticalDensityLast = average
        this.opticalDensityMin = values.length > 0 ? Math.min(...values) : null
        this.opticalDensityMax = values.length > 0 ? Math.max(...values) : null

        const concentrationValues = points.length > 0 ? this.concentrations.slice(points[0].index, points[points.length - 1].index) : []
        this.concentrationLast = this.average(concentrationValues)
        this.concentrationMin = concentrationValues.length > 0 ? Math.min(...concentrationValues) : null
        this.concentrationMax = concentrationValues.length > 0 ? Math.max(...concentrationValues) : null
        break
      }

    })
    // return
    // const acidity        = data.filter(el => el.acidity != null).map( el => Number(el.acidity) )
    // const temperature    = data.filter(el => el.temperature != null).map( el => Number(el.temperature) )
    // const opticalDensity = data.filter(el => el.optical_density != null).map( el => Number(el.optical_density) )
    // const concentration  = data.filter(el => el.concentration != null).map( el => Number(el.concentration) )
    //
    // this.acidityLast = this.average(acidity)
    // this.acidityMin = Math.min(...acidity)
    // this.acidityMax = Math.max(...acidity)
    //
    // this.temperatureLast = this.average(temperature)
    // this.temperatureMin = Math.min(...temperature)
    // this.temperatureMax = Math.max(...temperature)
    //
    // this.opticalDensityLast = this.average(opticalDensity)
    // this.opticalDensityMin = Math.min(...opticalDensity)
    // this.opticalDensityMax = Math.max(...opticalDensity)
    //
    // this.concentrationLast = this.average(concentration)
    // this.concentrationMin = Math.min(...concentration)
    // this.concentrationMax = Math.max(...concentration)
  }

  average(nums) {
    if (nums.length > 0) {
      return nums.reduce((a, b) => (a + b)) / nums.length;
    } else {
      return null
    }
  }

  setChartData(data) {
    const acidity = data.filter(el => el.acidity != null).map(el => [Date.parse(el.recorded_at), Number(el.acidity)])
    const temperature = data.filter(el => el.temperature != null).map(el => [Date.parse(el.recorded_at), Number(el.temperature)])
    const oxygen = data.filter(el => el.oxygen != null).map(el => [Date.parse(el.recorded_at), Number(el.oxygen)])
    this.concentrations = data.filter(el => el.concentration != null).map(el => Number(el.concentration))

    const opt_density = data.filter(el => el.optical_density != null).map(el => ({
      x: Date.parse(el.recorded_at),
      y: Number(el.optical_density),
      mixerSpeed: Number(el.mixer_speed),
      flowRate: Number(el.flow_rate)
    }))

    const titrant_consumption = data.filter(el => el.titrant_consumption != null).map(el => ({
      x: Date.parse(el.recorded_at),
      y: Number(el.titrant_consumption),
      methane_consumption: Number(el.methane_consumption),
      air_consumption: Number(el.air_consumption)
    }))

    const flow_rate = data.filter(el => el.optical_density != null && el.flow_rate != null).map(el => [
      Date.parse(el.recorded_at),
      Number(el.optical_density) - Number(el.flow_rate),
      Number(el.optical_density) + Number(el.flow_rate)
    ])

    const smoothedOD = calcSmoothedOD(data)
    const smoothedGrowSpeed = calcGrowSpeed(data)
    const relativeGrowSpeed = calcRelativeGrowSpeed(data)

    this.highchart.series[0].setData(acidity, false)
    this.highchart.series[1].setData(temperature, false)
    this.highchart.series[2].setData(opt_density, false)
    this.highchart.series[3].setData(flow_rate, false)
    this.highchart.series[4].setData(titrant_consumption, false)
    this.highchart.series[5].setData(smoothedOD.map(el => [
      Date.parse(el.recorded_at),
      el.smoothedOD
    ]))
    this.highchart.series[6].setData(smoothedGrowSpeed.map(el => [
      Date.parse(el.recorded_at),
      el.smoothedGrowSpeed
    ]))
    this.highchart.series[7].setData(relativeGrowSpeed.map(el => [
      Date.parse(el.recorded_at),
      el.relativeGrowSpeed
    ]))
    this.highchart.series[8].setData(oxygen)

    const comments = data.map((el, index) => ({index: index, comment: el.comment}))
      .filter(el => el.comment !== null)

    comments.forEach(el => {
      const point = this.highchart.series[2].points[el.index]
      point.update({
        dataLabels: {
          enabled: true,
          format: el.comment,
          align: 'center',
          verticalAlign: 'top',
          y: 10
        }
      })

      this.highchart.xAxis[0].addPlotLine({
        value: point.x,
        color: '#FF0000',
        width: 1,
        className: 'comment'
      })

    })

    this.highchart.hideLoading()
  }

  setSowings(data) {
    data.forEach(sowing => {
      this.highchart.xAxis[0].addPlotLine({
        value: Date.parse(sowing.sowed_at),
        color: '#0B3954',
        width: 1,
        label: {
          text: sowing.name,
          rotation: 270,
          x: 15,
          verticalAlign: 'middle',
          textAlign: 'center',
          style: {
            color: '#5E6472',
            fontWeight: 'bold'
          }
        }
      })
    })
  }

  formatNumber(value, digits = 1) {
    return (Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits)
  }

  set acidityLast(value) {
    this.acidityLastTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set acidityMin(value) {
    this.acidityMinTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set acidityMax(value) {
    this.acidityMaxTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set temperatureLast(value) {
    this.temperatureLastTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set temperatureMin(value) {
    this.temperatureMinTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set temperatureMax(value) {
    this.temperatureMaxTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set opticalDensityLast(value) {
    this.opticalDensityLastTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set opticalDensityMin(value) {
    this.opticalDensityMinTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set opticalDensityMax(value) {
    this.opticalDensityMaxTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set concentrationLast(value) {
    this.concentrationLastTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set concentrationMin(value) {
    this.concentrationMinTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set concentrationMax(value) {
    this.concentrationMaxTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  get highchart() {
    return this.chartTarget.highchart
  }

  triggerComments(event) {
    const element = event.target
    this.showComments = !this.showComments
    element.innerText = this.showComments ? 'Hide comments' : 'Show comments'
    const displayValue = this.showComments ? 'display' : 'none'
    this.highchart.xAxis[0].plotLinesAndBands.forEach(el => {
      if (el.options.className === 'comment') {
        el.options.width = this.showComments ? 1 : 0
      }
    })

    this.highchart.update({
      plotOptions: {
        series: {
          dataLabels: {
            style: {
              display: displayValue,
            }
          }
        }
      }
    })

    this.highchart.xAxis[0].update()
  }

  setExtremes(event) {
    this.setIndicators(event.min, event.max)
  }

  get chartOptions() {
    const showHideAxisTitle = {
      hide: function() {
        this.yAxis.axisTitle.attr({text: null})
      },
      show: function() {
        this.yAxis.axisTitle.attr({text: this.yAxis.userOptions.title.text})
      }
    }

    const pointFormatter = function() {
      const selectedX = this.x
      let str = ""
      this.series.chart.series.forEach(s => {
        if (s.name == "Flow rate") {
          return
        }

        // console.log(s.points)
        let p = s.points.find(p => {
          return p.x == selectedX
        })
        if (p) {
          str += `
            ${s.name}: <b>${p.y.toFixed(2)}</b><br>
          `

          if (s.name == "Optical density") {
            str += `
              Flow rate: <b>${p.flowRate.toFixed(2)}</b><br>
            `
          }
        }
      })
      return str
      // return `
      //   ${this.series.name}: <b>${this.y}</b><br>
      //
      //   <span style="color:${this.series.color}">●</span> ${this.series.name}: <b>${this.y}</b><br>
      //   <span style="color:${this.series.color}">-</span> Temperature: <b>${this.options.temperature}</b><br>
      //   <span style="color:${this.series.color}">-</span> Flow rate: <b>${this.options.flowRate}</b><br>
      //   <span style="color:${this.series.color}">-</span> Mixer speed: <b>${this.options.mixerSpeed}</b><br>
      // `
    }

    return {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          position: {
            align: 'left',
            x: 10,
          }
        }
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: true,
        xDateFormat: '%d.%m.%Y %H:%M',
        crosshairs: true,
        shared: false,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
        },
        series: {
          turboThreshold: 2000,
          marker: {
            radius: 1
          },
          dataLabels: {
            shape: 'callout',
            backgroundColor: 'rgba(0, 0, 0, 0.57)',
            style: {
              color: '#FFFFFF',
              textShadow: 'none',
              width: '200px'
            }
          }
        }
      },
      xAxis: {
        type: 'datetime',
        events: {
          setExtremes: this.setExtremes.bind(this)
        }
      },
      yAxis: [
        { // 0
          title: {
            text: 'pH',
            style: {
              color: '#cccccc' //'#7cb5ec'
            }
          },
          labels: {
            style: {
              color: '#cccccc' //'#7cb5ec'
            }
          }
        },
        { // 1
          title: {
            text: 'Temperature',
            style: {
              color: '#434348'
            }
          },
          labels: {
            style: {
              color: '#434348'
            }
          }
        },
        { // 2
          title: {
            text: 'Optical density',
            style: {
              color: '#90ed7d'
            }
          },
          labels: {
            style: {
              color: '#90ed7d'
            }
          },
          opposite: true,
          min: 0,
        },
        { // 3
          title: {
            text: 'Titrant consumption',
            style: {
              color: '#f7a35c',
            }
          },
          labels: {
            style: {
              color: '#f7a35c',
            }
          },
          opposite: true,
          visible: false
        },
        { // 4
          title: {
            text: 'Growth rate',
            style: {
              color: '#8C4843'
            }
          },
          labels: {
            style: {
              color: '#8C4843'
            }
          },
          visible: false,
          opposite: true
        },
        { // 5
          title: {
            text: 'Rel. growth rate',
            style: {
              color: '#8C4843'
            }
          },
          labels: {
            style: {
              color: '#8C4843'
            }
          },
          visible: false,
          opposite: true
        },
        { // 6
          title: {
            text: 'Oxygen',
            style: {
              color: '#7cb5ec' // '#CC00CC'
            }
          },
          labels: {
            style: {
              color: '#7cb5ec' // '#CC00CC'
            }
          },
          visible: true,
          opposite: true,
        },
      ],
      series: [
        { // 0
          name: 'pH',
          type: 'spline',
          color: '#cccccc', // '#7cb5ec',
          yAxis: 0,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
        { // 1
          name: 'Temperature',
          type: 'spline',
          color: '#434348',
          yAxis: 1,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
        { // 2
          name: 'Optical density',
          type: 'spline',
          marker: {
            enabled: true,
            radius: 3,
            symbol: "cirle",
          },
          color: '#90ed7d',
          yAxis: 2,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
        { // 3
          name: 'Flow rate',
          type: 'areasplinerange',
          lineWidth: 0,
          linkedTo: ':previous',
          color: '#90ed7d',
          yAxis: 2,
          fillOpacity: 0.5,
          zIndex: 0,
          marker: {
              enabled: false
          },
          enableMouseTracking: false
        },
        { // 4
          name: 'Titrant consumption',
          type: 'spline',
          color: '#f7a35c',
          yAxis: 3,
          tooltip: {
            pointFormatter: function() {
              return `
                <span style="color:${this.series.color}">●</span> ${this.series.name}: <b>${this.y}</b> ml/h<br>
                <span style="color:${this.series.color}">-</span> Metan consumption: <b>${this.options.methane_consumption}</b> ml/h<br>
                <span style="color:${this.series.color}">-</span> Air consumption: <b>${this.options.air_consumption}</b> ml/h<br>
              `
            }
          }
        },
        { // 5
          name: 'Smoothed OD',
          type: 'spline',
          color: '#407076',
          yAxis: 2,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
        { // 6
          name: 'OD change rate smoothed',
          type: 'spline',
          color: '#8C4843',
          yAxis: 4,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
        { // 7
          name: 'Rel. OD change rate',
          type: 'spline',
          color: '#FE938C',
          yAxis: 5,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
        { // 8
          name: 'Oxygen',
          type: 'spline',
          color: '#7cb5ec', // '#CC00CC',
          yAxis: 6,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
        },
      ]
    }
  }
}
