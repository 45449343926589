import { Controller } from "stimulus"
import Highcharts from 'highcharts'

export default class extends Controller {
  static targets = [ 'chart' ]

  static values = {
    opticalDensitiesUrl: String,
    sowingsUrl: String,
  }

  connect() {
    this.initChart()
    this.fetchRecords()
    this.fetchSowings()
  }

  initChart() {
    this.chartTarget.highchart = new Highcharts.chart(this.chartTarget, this.chartOptions)
  }

  fetchRecords() {
    fetch(this.opticalDensitiesUrlValue, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      }
    }).then(response => response.json())
      .then(data => this.setData(data))
  }

  fetchSowings() {
    fetch(this.sowingsUrlValue, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      }
    }).then(response => response.json())
      .then(data => this.setSowings(data))
  }

  setData(data) {
    const opt_density = data.map( el => [Date.parse(el[0]), el[1]] )
    this.highchart.series[0].setData(opt_density)
  }

  setSowings(data) {
    data.forEach(sowing => {
      this.highchart.xAxis[0].addPlotLine({
        value: Date.parse(sowing.sowed_at),
        color: '#0B3954',
        width: 1
      })
    })
  }

  get highchart() {
    return this.chartTarget.highchart;
  }

  get chartOptions() {
    return {
      title: {
        text: null
      },
      credits: false,
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
        min: 0,
        tickInterval: 0.1,
        lineWidth: 0,
        gridLineWidth:0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
      },
      xAxis: {
        labels: {
          enabled: false
        },
        tickLength: 0
      },
      series: [
        {
          name: 'Optical density',
          type: 'area',
          enableMouseTracking: false,
          color: '#90ed7d'
        }
      ]
    }
  }
}
