import { Controller } from "stimulus"
import { calcSmoothedOD, calcGrowSpeed, calcRelativeGrowSpeed } from 'utils/growSpeed'

const Highcharts = require('highcharts')
require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/boost')(Highcharts)

export default class extends Controller {
  static targets = [
    'chart',
    'acidityLast',
    'acidityMin',
    'acidityMax',
    'temperatureLast',
    'temperatureMin',
    'temperatureMax',
    'opticalDensityLast',
    'opticalDensityMin',
    'opticalDensityMax',
    'concentrationLast',
    'concentrationMin',
    'concentrationMax'
  ]

  static values = {
    acidityUrl: String,
    opticalDensityUrl: String,
    oxygenUrl: String,
    temperatureUrl: String,
    detailedMetrics: Boolean,
  }

  connect() {
    if (this.initialized) return

    this.initialized = true

    setTimeout(() => {
      this.showComments = true
      this.initChart()
      this.reloadData()
    }, 100)
  }

  toggleDetailedMetrics() {
    this.detailedMetricsValue = !this.detailedMetricsValue
  }

  detailedMetricsValueChanged() {
    console.log("detailedMetricsValueChanged")
    if (this.chartTarget.highchart) {
      this.reloadData()
    }
  }

  reloadData() {
    console.log("reloadData", this, this.highchart)
    this.highchart.showLoading()

    if (this.detailedMetricsValue) {
      this.dataFetchersCount = 4
      this.fetchData(this.opticalDensityUrlValue, 2)
      this.fetchData(this.oxygenUrlValue, 8)
      this.fetchData(this.acidityUrlValue, 0)
      this.fetchData(this.temperatureUrlValue, 1)
    } else {
      this.dataFetchersCount = 1
      this.fetchData(this.opticalDensityUrlValue, 2)
    }
  }

  initChart() {
    console.log("initChart")
    this.chartTarget.highchart = new Highcharts.chart(this.chartTarget, this.chartOptions)
  }

  fetchData(url, seriesIndex) {
    console.log("Fetching data", url)
    $.ajax({
      url: url,
      dataType: 'json',
      success: function(data) {
        // console.log("Fetching data data", data)
        this.setData(data, seriesIndex)
      }.bind(this)
    })
  }

  setData(data, seriesIndex) {
    if (seriesIndex == 2) { // Optical Density
      this.setOpticalDensityData(data)

      if (!this.detailedMetricsValue) {
        const acidities = data.map(function(el) {
          return [Date.parse(el.recorded_at), el.acidity]
        })
        const temperatures = data.map(function(el) {
          return [Date.parse(el.recorded_at),el.temperature ]
        })
        const oxygens = data.map(function(el) {
          return [Date.parse(el.recorded_at), el.oxygen]
        })

        this.highchart.series[8].update({
          data: oxygens,
          type: "spline",
        })
        this.highchart.series[0].update({
          data: acidities,
          type: "spline",
        })
        this.highchart.series[1].update({
          data: temperatures,
          type: "spline",
        })
      }
    } else { // acidities, temperatures, oxygens
      data = data.map(function(record) {
        return [Date.parse(record[0]), record[1]]
      })
      this.highchart.series[seriesIndex].update({
        data: data,
        type: 'line',
      })
    }

    this.dataFetchersCount -= 1
    if (this.dataFetchersCount < 1) {
      this.highchart.hideLoading()
      this.setIndicators()
    }
  }

  setOpticalDensityData(data) {
    const smoothedOD = calcSmoothedOD(data)
    const smoothedGrowSpeed = calcGrowSpeed(data)
    const relativeGrowSpeed = calcRelativeGrowSpeed(data)
    const flow_rate = data.filter(el => el.optical_density != null && el.flow_rate != null).map(el => [
      Date.parse(el.recorded_at),
      Number(el.optical_density) - Number(el.flow_rate),
      Number(el.optical_density) + Number(el.flow_rate)
    ])

    data = data.map(function(record, index) {
      return {
        x: Date.parse(record.recorded_at),
        y: Number(record.optical_density),
        concentration: Number(record.concentration),
        acidity: Number(record.acidity),
        temperature: Number(record.temperature),
        flow_rate: Number(record.flow_rate),
        oxygen: Number(record.oxygen),
      }
    })
    this.highchart.series[2].setData(data)

    this.highchart.series[5].setData(smoothedOD.map(el => [
      Date.parse(el.recorded_at),
      el.smoothedOD,
    ]))
    this.highchart.series[6].setData(smoothedGrowSpeed.map(el => [
      Date.parse(el.recorded_at),
      el.smoothedGrowSpeed,
    ]))
    this.highchart.series[7].setData(relativeGrowSpeed.map(el => [
      Date.parse(el.recorded_at),
      el.relativeGrowSpeed,
    ]))

    this.highchart.series[3].setData(flow_rate)
  }

  setIndicators(min, max) {
    // return
    console.log("setIndicators", min, max)
    // console.log(this.highchart.series[0])
    // return
    this.highchart.series.forEach(s => {
      if (["pH", "Temperature", "Optical density"].indexOf(s.name) == -1) {
        // console.log(s.name, "No")
        return
      }

      let values = s.processedYData
      // console.log(s.name, "points length before period filter", points.length)
      if (min != undefined && max != undefined) {
        values = values.filter((value, index) => {
          if (s.processedXData[index] < min) {
            return false
          }
          if (s.processedXData[index] > max) {
            return false
          }
          return true
        })
      }

      const average = this.average(values)

      switch (s.name) {
      case "pH":
        this.acidityLast = average
        this.acidityMin = values.length > 0 ? Math.min(...values) : null
        this.acidityMax = values.length > 0 ? Math.max(...values) : null
        break
      case "Temperature":
        // console.log("Temperature", s)
        this.temperatureLast = average
        this.temperatureMin = values.length > 0 ? Math.min(...values) : null
        this.temperatureMax = values.length > 0 ? Math.max(...values) : null
        break
      case "Optical density":
        // console.log("OD", s)
        this.opticalDensityLast = average
        this.opticalDensityMin = values.length > 0 ? Math.min(...values) : null
        this.opticalDensityMax = values.length > 0 ? Math.max(...values) : null

        const concentrationValues = s.points.length > 0 ? s.points.filter(p => {
          if (p.x < min) {
            return false
          }
          if (p.x > max) {
            return false
          }
          return true
        }).map(p => p.concentration) : []
        this.concentrationLast = this.average(concentrationValues)
        this.concentrationMin = concentrationValues.length > 0 ? Math.min(...concentrationValues) : null
        this.concentrationMax = concentrationValues.length > 0 ? Math.max(...concentrationValues) : null
        break
      }

    })
  }

  average(nums) {
    if (nums.length > 0) {
      return nums.reduce((a, b) => (a + b)) / nums.length;
    } else {
      return null
    }
  }

  setSowings(data) {
    data.forEach(sowing => {
      this.highchart.xAxis[0].addPlotLine({
        value: Date.parse(sowing.sowed_at),
        color: '#0B3954',
        width: 1,
        label: {
          text: sowing.name,
          rotation: 270,
          x: 15,
          verticalAlign: 'middle',
          textAlign: 'center',
          style: {
            color: '#5E6472',
            fontWeight: 'bold'
          }
        }
      })
    })
  }

  formatNumber(value, digits = 1) {
    return (Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits)).toFixed(digits)
  }

  set acidityLast(value) {
    this.acidityLastTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set acidityMin(value) {
    this.acidityMinTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set acidityMax(value) {
    this.acidityMaxTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set temperatureLast(value) {
    this.temperatureLastTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set temperatureMin(value) {
    this.temperatureMinTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set temperatureMax(value) {
    this.temperatureMaxTarget.innerText = value != null ? this.formatNumber(value) : "--.-"
  }

  set opticalDensityLast(value) {
    this.opticalDensityLastTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set opticalDensityMin(value) {
    this.opticalDensityMinTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set opticalDensityMax(value) {
    this.opticalDensityMaxTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set concentrationLast(value) {
    this.concentrationLastTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set concentrationMin(value) {
    this.concentrationMinTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  set concentrationMax(value) {
    this.concentrationMaxTarget.innerText = value != null ? this.formatNumber(value, 2) : "--.-"
  }

  get highchart() {
    return this.chartTarget.highchart
  }

  triggerComments(event) {
    const element = event.target
    this.showComments = !this.showComments
    element.innerText = this.showComments ? 'Hide comments' : 'Show comments'
    const displayValue = this.showComments ? 'display' : 'none'
    this.highchart.xAxis[0].plotLinesAndBands.forEach(el => {
      if (el.options.className === 'comment') {
        el.options.width = this.showComments ? 1 : 0
      }
    })

    this.highchart.update({
      plotOptions: {
        series: {
          dataLabels: {
            style: {
              display: displayValue,
            }
          }
        }
      }
    })

    this.highchart.xAxis[0].update()
  }

  setExtremes(event) {
    this.setIndicators(event.min, event.max)
  }

  get chartOptions() {
    const showHideAxisTitle = {
      hide: function() {
        this.yAxis.axisTitle.attr({text: null})
      },
      show: function() {
        this.yAxis.axisTitle.attr({text: this.yAxis.userOptions.title.text})
      }
    }

    const pointFormatter = function(a, b, c) {
      // console.log(this)
      const selectedX = this.x
      let str = ""

      if (this.series.name == "Optical density") {
        str += `
          pH: <b>${this.acidity.toFixed(2)}</b><br>
          Temperature: <b>${this.temperature.toFixed(2)}</b><br>
          ${this.series.name}: <b>${this.y.toFixed(2)}</b><br>
          Flow rate: <b>${this.flow_rate.toFixed(2)}</b><br>
          Oxygen: <b>${this.oxygen.toFixed(2)}</b><br>
        `
      } else {
        str += `
          ${this.series.name}: <b>${this.y.toFixed(2)}</b><br>
        `
      }

      return str

      this.series.chart.series.forEach(s => {
        //return // temp

        if (s.name == "Flow rate") {
          return
        }

        // console.log(s.points)
        let p = s.points.find(p => {
          return p.x == selectedX
        })
        if (p) {
          console.log(p)
          str += `
            ${s.name}: <b>${p.y.toFixed(2)}</b><br>
          `

          if (s.name == "Optical density") {
            //str += `
            //  Flow rate: <b>${p.flowRate.toFixed(2)}</b><br>
            //`
          }
        }
      })
      return str
      // return `
      //   ${this.series.name}: <b>${this.y}</b><br>
      //
      //   <span style="color:${this.series.color}">●</span> ${this.series.name}: <b>${this.y}</b><br>
      //   <span style="color:${this.series.color}">-</span> Temperature: <b>${this.options.temperature}</b><br>
      //   <span style="color:${this.series.color}">-</span> Flow rate: <b>${this.options.flowRate}</b><br>
      //   <span style="color:${this.series.color}">-</span> Mixer speed: <b>${this.options.mixerSpeed}</b><br>
      // `
    }

    return {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          position: {
            align: 'left',
            x: 10,
          }
        }
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: true,
        xDateFormat: '%d.%m.%Y %H:%M',
        crosshairs: true,
        shared: false,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false,
            symbol: "circle",
          },
        },
        line: {
          marker: {
            symbol: "circle",
          }
        },
        series: {
          turboThreshold: 2000,
          // marker: {
          //   radius: 1
          // },
          dataLabels: {
            shape: 'callout',
            backgroundColor: 'rgba(0, 0, 0, 0.57)',
            style: {
              color: '#FFFFFF',
              textShadow: 'none',
              width: '200px'
            }
          }
        }
      },
      xAxis: {
        type: 'datetime',
        events: {
          setExtremes: this.setExtremes.bind(this)
        }
      },
      yAxis: [
        { // 0
          title: {
            text: 'pH',
            style: {
              color: '#cccccc' //'#7cb5ec'
            }
          },
          labels: {
            style: {
              color: '#cccccc' //'#7cb5ec'
            }
          }
        },
        { // 1
          title: {
            text: 'Temperature',
            style: {
              color: '#434348'
            }
          },
          labels: {
            style: {
              color: '#434348'
            }
          }
        },
        { // 2
          title: {
            text: 'Optical density',
            style: {
              color: '#90ed7d'
            }
          },
          labels: {
            style: {
              color: '#90ed7d'
            }
          },
          opposite: true,
          min: 0,
        },
        { // 3
          title: {
            text: 'Titrant consumption',
            style: {
              color: '#f7a35c',
            }
          },
          labels: {
            style: {
              color: '#f7a35c',
            }
          },
          opposite: true,
          visible: false
        },
        { // 4
          title: {
            text: 'Growth rate',
            style: {
              color: '#8C4843'
            }
          },
          labels: {
            style: {
              color: '#8C4843'
            }
          },
          visible: false,
          opposite: true
        },
        { // 5
          title: {
            text: 'Rel. growth rate',
            style: {
              color: '#8C4843'
            }
          },
          labels: {
            style: {
              color: '#8C4843'
            }
          },
          visible: false,
          opposite: true
        },
        { // 6
          title: {
            text: 'Oxygen',
            style: {
              color: '#7cb5ec' // '#CC00CC'
            }
          },
          labels: {
            style: {
              color: '#7cb5ec' // '#CC00CC'
            }
          },
          visible: true,
          opposite: true,
        },
      ],
      series: [
        { // 0
          name: 'pH',
          type: 'line',
          boostThreshold: 1,
          color: '#cccccc', // '#7cb5ec',
          yAxis: 0,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
          visible: true,
        },
        { // 1
          name: 'Temperature',
          type: 'line',
          // marker: {
          //   enabled: true,
          // //   radius: 3,
          //   symbol: "cirle",
          // },
          color: '#434348',
          yAxis: 1,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
          boostThreshold: 1,
        },
        { // 2
          name: 'Optical density',
          type: 'spline',
          marker: {
            enabled: true,
            radius: 3,
            symbol: "cirle",
          },
          color: '#90ed7d',
          yAxis: 2,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
          visible: true,
        },
        { // 3
          name: 'Flow rate',
          type: 'areasplinerange',
          lineWidth: 0,
          linkedTo: ':previous',
          color: '#90ed7d',
          yAxis: 2,
          fillOpacity: 0.5,
          zIndex: 0,
          marker: {
            enabled: false
          },
          enableMouseTracking: false
        },
        { // 4
          name: 'Titrant consumption',
          type: 'spline',
          color: '#f7a35c',
          yAxis: 3,
          tooltip: {
            pointFormatter: function() {
              return `
                <span style="color:${this.series.color}">●</span> ${this.series.name}: <b>${this.y}</b> ml/h<br>
                <span style="color:${this.series.color}">-</span> Metan consumption: <b>${this.options.methane_consumption}</b> ml/h<br>
                <span style="color:${this.series.color}">-</span> Air consumption: <b>${this.options.air_consumption}</b> ml/h<br>
              `
            }
          },
          visible: false,
        },
        { // 5
          name: 'Smoothed OD',
          type: 'spline',
          color: '#407076',
          yAxis: 2,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
          visible: true,
        },
        { // 6
          name: 'OD change rate smoothed',
          type: 'spline',
          color: '#8C4843',
          yAxis: 4,
          tooltip: {
            pointFormatter: pointFormatter
          },
          visible: true,
        },
        { // 7
          name: 'Rel. OD change rate',
          type: 'spline',
          color: '#FE938C',
          yAxis: 5,
          tooltip: {
            pointFormatter: pointFormatter
          },
          visible: true,
        },
        { // 8
          name: 'Oxygen',
          type: 'line',
          color: '#7cb5ec', // '#CC00CC',
          yAxis: 6,
          events: showHideAxisTitle,
          tooltip: {
            pointFormatter: pointFormatter
          },
          boostThreshold: 1,
          visible: true,
        },
      ]
    }
  }
}
