import { Controller } from "stimulus"

export default class extends Controller {
    static get targets() {
        return ['file','fileNameOutput']
    }

    setCaption() {
        if (this.fileTarget.files.length > 0) {
            this.fileNameOutputTarget.textContent = this.fileTarget.files[0].name
        }
    }
}
