import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (this.element.value) return

    this.element.value = this.localTime
  }

  get localTime() {
    const date    = new Date()
    const day     = `0${date.getDate()}`.slice(-2)
    const month   = `0${date.getMonth() + 1}`.slice(-2)
    const year    = date.getFullYear()
    const hours   = `0${date.getHours()}`.slice(-2)
    const minutes = `0${date.getMinutes()}`.slice(-2)

    return `${year}-${month}-${day}T${hours}:${minutes}`
  }
}
